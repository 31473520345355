import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Footer from './components/footer/Footer';
import ScrollBar from './ScrollBar';
import Galerij from "./pages/Galerij";
import Privacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";
import Modal from "./components/modal/Modal";
import LanguageChecker from './LanguageChecker';

const App = function(){
    let languageSwitchText;

    if(LanguageChecker.isEnglish()){
        languageSwitchText = "Change to Dutch";
    }else{
        languageSwitchText = "Change to English";
    }
    return (
        <div className="App">
            <Router>
                <div>
                    <div className="text-light language-text-font header-scroll-down-font-size position-fixed left-0 p-3 pl-4 mr-auto bottom-0 mt-auto switch-language z-index-100">{languageSwitchText}</div>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/home_en' component={Home} />
                        <Route exact path='/galerij' component={Galerij} />
                        <Route exact path='/gallery_en' component={Galerij} />
                        <Route exact path='/privacy' component={Privacy} />
                        <Route exact path='/privacy_en' component={Privacy} />
                        <Route exact path='/not_found' component={NotFound} />
                        <Route exact path='https://gitlab.com/s4nderdevelopment' />
                    </Switch>
                    <Modal />
                    <Footer/>
                </div>
            </Router>
            <ScrollBar/>
        </div>
    );
}

export default App;
