import React from 'react';

const PrivacyAndItemInner = function(props){
    let description = props.privacyInfo.description;
    description = description.replace(/(\r){0,1}\n/g, "<br/>");
    let components = [];

    let imgCLASSES = "";
    if(props.privacyInfo.hasOwnProperty("imgCLASSES")){
        imgCLASSES = props.privacyInfo.imgCLASSES;
    }

    let ImgCreditComponent = function(){
        return (
            <div></div>
        );
    };

    let imgCREDIT = {};
    if(props.privacyInfo.hasOwnProperty("imgCREDIT")){
        imgCREDIT = props.privacyInfo.imgCREDIT;

        ImgCreditComponent = function(props){
            return (
                <a className="text-center mt-2" href={props.data.link} target="_blank" rel="noreferrer" >
                    {props.data.text}
                </a>
            );
        };
    }

    let imgSTYLES = {};
    if(props.privacyInfo.hasOwnProperty("imgSTYLES")){
        imgSTYLES = props.privacyInfo.imgSTYLES;
    }

    if(props.imgLeft){
        components.push(
            <div key={components.length} className="px-1 col col-12 col-lg-6 d-none d-lg-flex justify-content-center">
                <div className="w-50 h-100 px-1 py-0 mx-auto d-flex flex-column">
                    <img loading="lazy" alt={props.privacyInfo.imgALT} src={props.privacyInfo.imgHREF} className={"lazy img-fluid img-contain w-100 mh-100 my-auto d-flex" + imgCLASSES} style={imgSTYLES} />
                    <ImgCreditComponent data={imgCREDIT} />
                </div>
            </div>
        );
    }
    components.push(
        <div key={components.length} className="px-1 col col-12 col-lg-6 my-auto">
            <h3 className="position-relative text-center font-weight-bold text-uppercase text-dark mb-5">{props.privacyInfo.name}</h3>
            <h5 className={"position-relative font-weight-bold text-dark text-default-case " + props.privacyInfo.classes} dangerouslySetInnerHTML={{__html: description}} ></h5>
        </div>
    );

    components.push(
        <div key={components.length} className={"px-1 col col-12 col-lg-6 d-flex " + (props.imgLeft ? "d-lg-none " : "") + "justify-content-center"}>
            <div className="w-50 h-auto px-1 py-0 mx-auto d-flex flex-column">
                <img loading="lazy" alt={props.privacyInfo.imgALT} src={props.privacyInfo.imgHREF} className={"lazy img-fluid img-contain w-100 mh-100 my-auto d-flex " + imgCLASSES} style={imgSTYLES} />
                <ImgCreditComponent data={imgCREDIT} />
            </div>
        </div>
    );
    return components;
}

const PrivacyAndItem = function(props) {
    return (
        <div className="PrivacyAndItem">
            <div className="fade-in fade-in-from-top w-100 position-relative mb-5" data-fade-in-start="0.9">
                <hr className="position-relative w-10 mx-auto mb-3 min-width-200px bg-light" />
            </div>
            <div className="row mb-5" id={"privacy-" + props.privacyInfo.slug}>
                <div className={"col col-8 mx-auto fade-in fade-in-from-" + (props.imgLeft ? "left" : "right") +  " position-relative mb-0"} data-fade-in-start="0.9">
                    <div className="row h-100 align-content-center bg-white border-light rounded p-3">
                        <PrivacyAndItemInner privacyInfo={props.privacyInfo} imgLeft={props.imgLeft} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyAndItem;
