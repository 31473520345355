import React from 'react';
import "./Navbar.css";

const NavbarItemDropdown = function(props){
    let elements = [];

    let keys = Object.keys(props.links);
    
    for(let i = 0; i < keys.length; i++){
        let key = keys[i];
        let value = props.links[key];
        
        let target = (value.match(/^http(s){0,1}:\/\//)?.length ?? 0) >= 1 ? "_blank" : "_self";

        elements.push(
            <a key={i} target={target} className={"dropdown-item" + (props.activePage === key ? " active" : "")} href={value} title={"Ga naar de pagina " + key}>{key}</a>
        );
    }

    return elements;
};

const NavbarItem = function(props) {
    if(typeof props.itemURL === "object"){
        return (
            <li className="nav-item dropdown ml-auto mr-1 ml-lg-2">
                <a className={"dropdown-toggle nav-link"} href={"/"} onClick={() => false} aria-expanded="false" data-toggle="dropdown" title={"Ga naar de pagina " + props.itemName}>{props.itemName}</a>
                <div className="dropdown-menu dropdown-menu-right">
                    <NavbarItemDropdown links={props.itemURL} activePage={props.activePage} />
                </div>
            </li>
        );
    }else{
        let target = (props.itemURL.match(/^http(s){0,1}:\/\//)?.length ?? 0) >= 1 ? "_blank" : "_self";
        
        return (
            <li className="nav-item ml-auto mr-1 ml-lg-2">
                <a target={target} className={"nav-link" + (props.activePage === props.itemName ? " active" : "")} href={props.itemURL} title={"Ga naar de pagina " + props.itemName}>{props.itemName}</a>
            </li>
        );
    }
}

export default NavbarItem;
