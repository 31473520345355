let english = false;
let dutch = false;

let forceEnglish = false;

/*
let languageCode = navigator.userLanguage || window.navigator.language;
let supportedLanguages = window.navigator.languages;
if (supportedLanguages && supportedLanguages.length > 0) {
    for(let i = 0; i < supportedLanguages.length; i++){
        if(supportedLanguages[i].toLowerCase().indexOf("nl") > -1){
            dutch = true;
            break;
        }
    }
    if(!dutch){
        english = true;
    }
} else {
    if (languageCode.toLowerCase().indexOf("nl") > -1) {
        dutch = true;
    } else {
        english = true;
    }
}*/

let pathName = window.location.pathname;

if(pathName.indexOf("_en") === pathName.length - "_en".length){
    english = true;
}else{
    dutch = true;
}

let isEnglish = function () {
    if(forceEnglish){
        return true;
    }
    return english;
}

let isDutch = function () {
    if(forceEnglish){
        return false;
    }
    return dutch;
}

let LanguageChecker = {
    isEnglish, 
    isDutch
};

export default LanguageChecker;
