import React, { useEffect } from 'react';
import "./ScrollBar.css";

const ScrollBar = function() {
    useEffect(() => {
        let $ = window.$;
        $(function(){
            let onScroll = function(delayed){
                //let scrollBarHeightPercentage = 25;
                let navbarHeight = $(".navbar").outerHeight();
                let wintop = $(window).scrollTop();
                let docheight = $(document).height();
                let winheight = $(window).height();

                let barTopPercentage = (wintop) / (docheight);
                let barTop = barTopPercentage * (winheight - navbarHeight);

                let barHeight = (winheight - navbarHeight) / docheight * 100.0;

                $(".body-scrollbar").css({"top": (navbarHeight + barTop) + "px", height: barHeight + "%"});
                if(!delayed){
                    setTimeout(function(){
                        onScroll(true);
                    }, 100);
                }
            };
            $(window).scroll(function(){
                onScroll(false);
            });
            onScroll();

            let prevDocHeight = $(document).outerHeight();
            let checkDocumentResize = function(){
                let newDocheight = $(document).outerHeight();
                if(prevDocHeight !== newDocheight){
                    prevDocHeight = newDocheight;
                    onScroll(false);
                }
                setTimeout(checkDocumentResize, 250);
            }
            setTimeout(checkDocumentResize, 250);

            let mouseDown = false;

            let bodyScrollBar = $(".body-scrollbar");

            let mouseStartY = 0;
            let wintopStart = 0;

            let mouseDownTouchStart = function(event) {
                mouseDown = true;
                if(event.touches && event.touches.length > 0){
                    mouseStartY = event.touches[0].clientY;
                }else{
                    mouseStartY = event.clientY;
                }
                
                wintopStart = $(window).scrollTop();
                return true;
            };

            $(bodyScrollBar).on('touchstart mousedown', function(e){
                e.preventDefault();
                mouseDownTouchStart(e);
            });

            let mouseMoveTouchMove = function(event) {
                if(mouseDown) {
                    event.preventDefault();
                    let mouseY = 0;
                    if(event.touches && event.touches.length > 0){
                        mouseY = event.touches[0].clientY;
                    }else{
                        mouseY = event.clientY;
                    }
                    let mouseDY = mouseY - mouseStartY;

                    let navbarHeight = $(".navbar").outerHeight();
                    let docheight = $(document).height();
                    let winheight = $(window).height();

                    let barHeight = (winheight - navbarHeight) / docheight * 100.0;

                    let scrollHeight = (wintopStart - barHeight) / docheight * (winheight - navbarHeight);
                    scrollHeight += mouseDY;
                    scrollHeight = scrollHeight * docheight / (winheight - navbarHeight);
                    
                    let wintop = scrollHeight;
                    
                    $(window).scrollTop(wintop);
                }
                return true;
            };

            $(window.document).on('touchmove mousemove', function(e){
                e.preventDefault();
                mouseMoveTouchMove(e);
            });
            $(window.document).on('mouseup touchend', function(event) {
                mouseDown = false;
                $(".body-scrollbar").css({"background-color": null});
                return true;
            });
            $(bodyScrollBar).on('touchstart', function(e) {
                e.preventDefault();
                $(bodyScrollBar).addClass('touch-hover');
            });
            $(window.document).on('touchend', function(e) {
                $(bodyScrollBar).removeClass('touch-hover');
            });
        });
    }, []);
    
    return (
        <div className="ScrollBar body-scrollbar">
        </div>
    );
}

export default ScrollBar;
