import React, { useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import HeaderText from '../components/header/HeaderText';
import Script from "../script";
import Projects from "../components/sections/project/Projects";
import ErvaringSection from '../components/sections/ervaring/ErvaringSection';

const Home = function() {
  useEffect(() => {
    Script();
  }, []);

  let activePage = "Home";
  return (
    <div className="Home">
      <Navbar activePage={activePage}/>
      <HeaderText activePage={"Home"}/>
      <Projects/>
      <ErvaringSection/>
    </div>
  );
}
;
export default Home;