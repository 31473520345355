import React from 'react';
import FooterItem from "./FooterItem";
import "./Footer.css";
import LanguageChecker from "../../LanguageChecker";

let footerItemsAll = {
    footerItems: {
        "Home": {
            url: "/",
            icon: "fa-home"
        },
        "Galerij": {
            url: "/galerij",
            icon: "fa-images"
        },
        "Privacy": {
            url: "/privacy",
            icon: "fa-shield-alt"
        }
    },
    footerItems_en: {
        "Home": {
            url: "/home_en",
            icon: "fa-home"
        },
        "Gallery": {
            url: "gallery_en",
            icon: "fa-images"
        },
        "Privacy": {
            url: "/privacy_en",
            icon: "fa-shield-alt"
        }
    }
}

const FooterItems = function(){
    let footerItems = LanguageChecker.isEnglish() ? footerItemsAll.footerItems_en : footerItemsAll.footerItems;
    let items = [];
    let itemNames = Object.keys(footerItems);
    for(let i = 0; i < itemNames.length; i++){
        items.push(
            <FooterItem key={i} name={itemNames[i]} linkTo={footerItems[itemNames[i]].url} icon={footerItems[itemNames[i]].icon}/>
        );
    }
    return items;
}

const Footer = function() {

    return (
        <footer className="Footer bg-primary w-100 mt-3" id="contact">
            <div className="container">
                <div className="row mb-4 d-flex flex-row justify-content-center flex-wrap">
                    {FooterItems()}
                </div>
                <div className="row mb-5">
                    <div className="col col-12">
                        <h5 className="text-light text-center">
                            &copy; Portfolio Sander Schoone 2020 - {new Date().getFullYear()}
                        </h5>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
