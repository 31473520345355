import React, { useEffect } from 'react';
import HeaderTextType from "./header-text-type";
import "./HeaderText.css";
import LanguageChecker from "../../LanguageChecker";

let $ = window.$;

const pages = {
    // Extra spaces => typing pause
    // This does not delay the backspace-time
    "Home": {
        headerText: [
            "Hi,          I&rsquo;m Sander Schoone 👋",
            "I am a full stack software developer",
            "Scroll down to view more",
        ],
        switchToDutch: "/",
        switchToEnglish: "/home_en"
    },
    "Privacy": {
        headerText: [
            "Privacy",
            "No personal data collection",
            "No cookies",
        ],
        switchToDutch: "/privacy",
        switchToEnglish: "/privacy_en"
    },
    "Galerij": {
        headerText: [
            "Images &amp; videos",
            "Take a look!"
        ],
        switchToDutch: "/galerij",
        switchToEnglish: "/gallery_en"
    }
};

const data = {
    pages: pages,
    pages_en: {
        "Home": pages.Home,
        "Privacy": pages.Privacy,
        "Gallery": pages.Galerij
    }
}

const HeaderText = function(props) {
    
    useEffect(() => {
        let pages = LanguageChecker.isEnglish() ? data.pages_en : data.pages;
        let languageSwitchLink = pages[props.activePage];

        if(LanguageChecker.isEnglish()){
            languageSwitchLink = languageSwitchLink.switchToDutch;
        }else{
            languageSwitchLink = languageSwitchLink.switchToEnglish;
        }

        $(".switch-language").click(function(){
            window.location = languageSwitchLink;
        });
        
        HeaderTextType(pages[props.activePage].headerText);
    }, [props.activePage]);
    
    return (
        <header className="HeaderText bg-header-img w-100 vh-50 vh-xl-100 bg-header-img-1 bg-primary d-flex flex-column align-content-center">
            <div className="header-container h1 typewrite h-auto d-flex mb-auto position-relative w-100" data-keep-text-after-finish="2000">
                <p className="text-light header-text header-text-font-size h-auto text-left"></p>
            </div>
            <div className="header-scroll-down h-auto align-self-bottom mb-0 justify-content-center flex-row">
                <p className="text-light header-text d-none d-xl-flex header-scroll-down-font-size h-auto w-auto hide-on-scroll mx-auto justify-content-center align-content-center p-4"><i className="fas fa-arrow-down d-flex my-auto mr-1"></i>Scroll down<i className="fas fa-arrow-down d-flex my-auto ml-1"></i></p>
            </div>
        </header>
    );
}

export default HeaderText;
