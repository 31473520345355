import React, { useEffect } from 'react';

const Project = function(props) {
    let showModal = (title, description, imageURL, techniques) => {
        let $ = window.$;
        let projectsModal = $('#projects-modal');

        if(techniques === undefined){
            techniques = [];
        }
        
        projectsModal.find(".modal-title").html(title);
        projectsModal.find(".modal-body-image").css({
            backgroundImage: "url(" + imageURL + ")"
        });
        let techniquesEl = projectsModal.find(".modal-body-techniques");
        let techniquesListEl = techniquesEl.find("ul");
        techniquesListEl.html("");

        if(techniques.length === 0){
            techniquesEl.addClass("d-none");
        }else{
            techniquesEl.removeClass("d-none");
            for(let technique of techniques){
                let item = $("<li></li>");
                item.html(technique);
                techniquesListEl.append(item);
            }
        }

        projectsModal.find(".modal-body-description").html(description);

        projectsModal.modal({
            show: true
        });
    };

    useEffect(() => {
        let slug = props.projectInfo.slug;
        if(window.location.hash === "#" + slug){
            let $ = window.$;
            let a = $("a[href='#" + slug + "']").first();
            if(a.length >= 1){
                try{
                    a[0].scrollIntoView({
                        behavior: "instant"
                    });
                }catch(e){
                    a[0].scrollIntoView();
                }
            }
            showModal(props.projectInfo.name + " - " + props.projectInfo.subtitle, props.projectInfo.description + " " + props.projectInfo.appendDescription, props.projectInfo.imgHREF, props.projectInfo.techniques);
        }
    }, [props.projectInfo]);

    let description = props.projectInfo.description;
    description = description.replace(/(\r){0,1}\n/g, "<br/>");
    return (
        <div className="Project d-flex col-12 col-md-6 col-xxl-3 mb-3">
            <a href={"#" + props.projectInfo.slug} onClick={() => {showModal(props.projectInfo.name + " - " + props.projectInfo.subtitle, props.projectInfo.description + " " + props.projectInfo.appendDescription, props.projectInfo.imgHREF, props.projectInfo.techniques)}} className="card text-decoration-none rounded w-100">
                <div className="card-header position-relative d-flex p-0" style={{height: "140px"}}>
                    <div className="d-flex col m-0 position-absolute w-100 h-100" style={{backgroundImage: "url(" + props.projectInfo.imgHREF + ")", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: "blur(3px) contrast(30%)"}}>
                    </div>
                    <div className="d-flex col m-0 position-absolute w-100 h-100 p-2" style={{zIndex: "1"}}>
                        <div className="d-flex col m-0 p-0" style={{backgroundImage: "url(" + props.projectInfo.imgHREF + ")", backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>
                    </div>
                </div>
                <div className="card-body px-3 pt-2 pb-3">
                    <h4 className="card-title" dangerouslySetInnerHTML={{__html: props.projectInfo.name}}></h4>
                    <h6 className="card-subtitle mb-2 text-muted">{props.projectInfo.subtitle}</h6>
                    <p className="card-text" dangerouslySetInnerHTML={{__html: description}}></p>
                </div>
            </a>
        </div>
    );
};

export default Project;
