import React, { useEffect } from 'react';
import LanguageChecker from '../../../LanguageChecker';

const GalerijItem = function(props) {
    let showModal = (title, imageURL, projectUrl) => {
        let $ = window.$;
        let projectsModal = $('#gallery-modal');
        
        projectsModal.find(".modal-title").html(title);
        projectsModal.find(".modal-body-image").css({
            backgroundImage: "url(" + imageURL + ")"
        });

        let description = LanguageChecker.isEnglish() ? "Click here to view the project." : "Klik hier om het bijbehorende project te bekijken.";

        let descriptionLink = $("<a></a>");
        descriptionLink.attr("href", projectUrl);
        descriptionLink.html(description);
        descriptionLink.addClass("text-decoration-underline");

        let descriptionEl = projectsModal.find(".modal-body-description");
        descriptionEl.html("");
        descriptionEl.append(descriptionLink);
        
        projectsModal.modal({
            show: true
        });
    };

    useEffect(() => {
        let slug = props.galerijItemInfo.slug;
        if(window.location.hash === "#" + slug){
            let $ = window.$;
            let a = $("a[href='#" + slug + "']").first();
            if(a.length >= 1){
                try{
                    a[0].scrollIntoView({
                        behavior: "instant"
                    });
                }catch(e){
                    a[0].scrollIntoView();
                }
            }
            showModal(props.galerijItemInfo.imageAlt, props.galerijItemInfo.imageUrl, props.galerijItemInfo.projectUrl);
        }
    }, [props.galerijItemInfo]);

    return (
        <a href={"#" + props.galerijItemInfo.slug} onClick={() => {showModal(props.galerijItemInfo.imageAlt, props.galerijItemInfo.imageUrl, props.galerijItemInfo.projectUrl); return false;}} className="col col-4 p-2">
            <img loading="lazy" src={props.galerijItemInfo.imageUrl} alt={props.galerijItemInfo.imageAlt} className="lazy img-fluid img-contain my-auto w-100 h-100" />
        </a>
    );
}

export default GalerijItem;