import React from 'react';
import LanguageChecker from "../../../LanguageChecker";
import Project from "./Project";

const ProjectItems = function(props){
    let projects = LanguageChecker.isEnglish() ? props.data.projects_en : props.data.projects;
    let projectComponents = [];
    for(let i = 0; i < projects.length; i++){
        projectComponents.push(
            <Project key={i} imgLeft={false} projectInfo={projects[i]}/>
        );
    }
    return projectComponents;
}

const Projects = function() {
    let data = {
        projects: [
            {
                name: "Vulnerability report GitLab",
                subtitle: "GitLab / HackerOne",
                slug: "vulnerability-report-gitlab",
                href: "#vulnerability-report-gitlab",
                description: "In januari 2021 heb ik een kwetsbaarheid gevonden bij GitLab waarmee een gebruiker een instelling voor zijn project kon wijzigen naar een niet-toegestane waarde. Deze bug heb ik gemeld bij GitLab via HackerOne (CVE-2021-39903, CVSS 6.5/10.0). Hiervoor heb ik een bounty gekregen.",
                appendDescription: "<br/>Je kunt het gepubliceerde rapport vinden op <a href='https://hackerone.com/reports/1086781' target='_blank' class='text-decoration-underline'>HackerOne</a> en de blog post op <a href='https://about.gitlab.com/releases/2021/10/28/security-release-gitlab-14-4-1-released/#change-project-visibility-to-a-restricted-option' target='_blank' class='text-decoration-underline'>GitLab Blog (s4nderdevelopment)</a>",
                imgHREF: "/projecten/vulnerability-report-gitlab/img/logo.webp",
                imgALT: "Vulnerability report GitLab",
                techniques: [
                    "BurpSuite / Postman",
                    "GitLab",
                    "Linux",
                    "Cloud (VPS)"
                ]
            },
            {
                name: "Klanten&ZeroWidthSpace;portaal",
                subtitle: "DataByte",
                slug: "customer-portal-databyte",
                href: "#customer-portal-databyte",
                description: "Tijdens mijn stage bij DataByte in Steenbergen heb ik een klantenportaal ontwikkeld waarmee klanten hun diensten van DataByte kunnen beheren. Ik heb de basis van het portaal opgezet en vervolgens de eerste module ontwikkeld, namelijk ‘Software’.",
                appendDescription: "<br/>Met deze module kunnen klanten feature-wensen indienen en bugs melden over andere softwarediensten van DataByte. Er worden automatisch taken aangemaakt in Azure DevOps Boards, die de ontwikkelaars kunnen oppakken. De klant kan ook de status van een wens of bug zien.",
                imgHREF: "/projecten/klantenportaal-databyte/img/logo.png",
                imgALT: "Klantenportaal DataByte",
                techniques: [
                    "C# ASP.NET, EntityFramework en Razor",
                    "Azure DevOps: Build/Release Pipelines",
                    "Microsoft SQL Server",
                    "Softwareontwikkeling in een team",
                ]
            },
            {
                name: "Websitebeheer &amp; SEO",
                subtitle: "Autobedrijf Schoone",
                slug: "autobedrijf-schoone-website-beheer-and-seo",
                href: "#autobedrijf-schoone-website-beheer-and-seo",
                description: "Voor Autobedrijf Schoone heb ik hun website geoptimaliseerd voor zoekmachines (SEO). Daardoor is de website beter vindbaar in Google en worden er meer afspraken gemaakt voor APK’s en onderhoud gemaakt.",
                appendDescription: "",
                imgHREF: "/projecten/website-en-seo-autobedrijf-schoone/img/logo.png",
                imgALT: "Websitebeheer &amp; SEO Autobedrijf Schoone",
                techniques: [
                    "WordPress",
                    "Google Lighthouse",
                    "Search Engine Optimization (SEO)"
                ]
            },
            {
                name: "Ansible Setup",
                subtitle: "Hobbyproject",
                slug: "ansible-setup",
                href: "#ansible-setup",
                description: "Ansible Playbook voor het opzetten van een nieuwe computer of server (VPS) met Linux. Het script installeert veelgebruikte tools voor een snelle start na het installeren van een nieuw systeem.",
                appendDescription: "<br/><a href='https://gitlab.com/s4nderdevelopment/ansible-setup/' target='_blank' class='text-decoration-underline'>Source code op GitLab</a>",
                imgHREF: "/projecten/ansible-setup/img/logo.png",
                imgALT: "Ansible Setup",
                techniques: [
                    "Bash",
                    "Linux",
                    "Ansible",
                    "Cloud (VPS)"
                ]
            },
            {
                name: "Store",
                subtitle: "Tax Model (Opleiding ICT)",
                slug: "store-tax-model",
                href: "#store-tax-model",
                description: "Ontwikkeling van een online store voor Tax Model.",
                appendDescription: "<br/><a href='https://gitlab.com/s4nderdevelopment/taxmodel-store/' target='_blank' class='text-decoration-underline'>Source code op GitLab</a>",
                imgHREF: "/projecten/store-tax-model/img/logo.png",
                imgALT: "Store Tax Model",
                techniques: [
                    "C#",
                    "MySQL database",
                    "JWT",
                    "Softwareontwikkeling in een team",
                ]
            },
            {
                name: "Beheersysteem voor inventaris",
                subtitle: "Dual Inventive (Opleiding ICT)",
                slug: "inventory-management-system-dual-inventive",
                href: "#inventory-management-system-dual-inventive",
                description: 'Ontwikkeling van een inventarissysteem voor Dual Inventive. Medewerkers kunnen uitgeleende artikelen uit het magazijn registreren en de status daarvan bijhouden.',
                appendDescription: "",
                imgHREF: "/projecten/inventory-management-system-dual-inventive/img/logo.webp",
                imgALT: "Inventory management system Dual Inventive",
                techniques: [
                    "C#",
                    "MySQL database",
                    "JWT",
                    "Softwareontwikkeling in een team",
                ]
            },
            {
                name: "S4Engine",
                subtitle: "Hobbyproject",
                slug: "s4engine",
                href: "#s4engine",
                description: 'Een game engine geschreven in C++ met ondersteuning voor OpenGL, scripts, materials, shaders, textures, meshes en meer assets. De engine werkt met een zelfgeschreven Entity Component System.',
                appendDescription: "",
                imgHREF: "/projecten/s4engine/img/logo.webp",
                imgALT: "S4Engine",
                techniques: [
                    "C++",
                    "OpenGL",
                    "Libraries als ImGui, tinyobjloader en GLFW"
                ]
            },
            {
                name: "Soft Cards",
                subtitle: "Opleiding ICT",
                slug: "soft-cards",
                href: "#project-soft-cards",
                description: 'Soft Cards is een visuele programmeertaal die bestaat uit fysieke kaarten. Deze kaarten kunnen worden gescand met een augmented reality-app op een smartphone of tablet, waarna het geprogrammeerde script een robot aanstuurt.',
                appendDescription: "",
                imgHREF: "/projecten/soft-cards/img/logo.webp",
                imgALT: "Soft Cards",
                techniques: [
                    "Unity Game Engine",
                    "Augmented Reality",
                    "Softwareontwikkeling in een team",
                ]
            }
        ],
        projects_en: [
            {
                name: "Vulnerability report GitLab",
                subtitle: "GitLab / HackerOne",
                slug: "vulnerability-report-gitlab",
                href: "#vulnerability-report-gitlab",
                description: "In january of 2021, I found a vulnerability in GitLab which gave a user the ability to change a project setting to a disallowed value. I reported this bug to GitLab on their HackerOne program (CVE-2021-39903, CVSS 6.5/10.0). I got a bounty for this report.",
                appendDescription: "<br/>You can find the published report at <a href='https://hackerone.com/reports/1086781' target='_blank' class='text-decoration-underline'>HackerOne</a> and the blog post at <a href='https://about.gitlab.com/releases/2021/10/28/security-release-gitlab-14-4-1-released/#change-project-visibility-to-a-restricted-option' target='_blank' class='text-decoration-underline'>GitLab Blog (s4nderdevelopment)</a>",
                imgHREF: "/projecten/vulnerability-report-gitlab/img/logo.webp",
                imgALT: "Vulnerability report GitLab",
                techniques: [
                    "BurpSuite / Postman",
                    "GitLab",
                    "Linux",
                    "Cloud (VPS)"
                ]
            },
            {
                name: "Customer portal",
                subtitle: "DataByte",
                slug: "customer-portal-databyte",
                href: "#customer-portal-databyte",
                description: "During my internship at DataByte in Steenbergen I created a customer portal. Customers can manage the services they use from DataByte. I built the web application from the ground up and I developed the first module called ‘Software’.",
                appendDescription: "<br/>This module allows customers to submit feature requests and report bugs for other software services from DataByte. Tasks will be created on Azure DevOps Boards, so the developers can start working on that task. The customer can also view the status of a feature or bug.",
                imgHREF: "/projecten/klantenportaal-databyte/img/logo.png",
                imgALT: "Customer portal DataByte",
                techniques: [
                    "C# ASP.NET, EntityFramework and Razor",
                    "Azure DevOps: Build/Release Pipelines",
                    "Microsoft SQL Server",
                    "Software development in a team",
                ]
            },
            {
                name: "Website configuration &amp; SEO",
                subtitle: "Autobedrijf Schoone",
                slug: "autobedrijf-schoone-website-beheer-and-seo",
                href: "#autobedrijf-schoone-website-beheer-and-seo",
                description: "For Autobedrijf Schoone, I optimized their website for search engines (SEO). The website now has an improved ranking in Google Search which has led to more people making appointments for maintenance of their car.",
                appendDescription: "",
                imgHREF: "/projecten/website-en-seo-autobedrijf-schoone/img/logo.png",
                imgALT: "Website configuration &amp; SEO Autobedrijf Schoone",
                techniques: [
                    "WordPress",
                    "Google Lighthouse",
                    "Search Engine Optimization (SEO)"
                ]
            },
            {
                name: "Ansible Setup",
                subtitle: "Hobby project",
                slug: "ansible-setup",
                href: "#ansible-setup",
                description: "Ansible Playbook for setting up a new computer or server (VPS) using Linux. The script will install often used software for a quick start after installing a new system.",
                appendDescription: "<br/><a href='https://gitlab.com/s4nderdevelopment/ansible-setup/' target='_blank' class='text-decoration-underline'>Source code on GitLab</a>",
                imgHREF: "/projecten/ansible-setup/img/logo.png",
                imgALT: "Ansible Setup",
                techniques: [
                    "Bash",
                    "Linux",
                    "Ansible",
                    "Cloud (VPS)"
                ]
            },
            {
                name: "Store",
                subtitle: "Tax Model (ICT study)",
                slug: "store-tax-model",
                href: "#store-tax-model",
                description: "Development of an online store for Tax Model.",
                appendDescription: "<br/><a href='https://gitlab.com/s4nderdevelopment/taxmodel-store/' target='_blank' class='text-decoration-underline'>Source code on GitLab</a>",
                imgHREF: "/projecten/store-tax-model/img/logo.png",
                imgALT: "Store Tax Model",
                techniques: [
                    "C#",
                    "MySQL database",
                    "JWT",
                    "Software development in a team",
                ]
            },
            {
                name: "Inventory management system",
                subtitle: "Dual Inventive (ICT study)",
                slug: "inventory-management-system-dual-inventive",
                href: "#inventory-management-system-dual-inventive",
                description: 'Development of an inventory management system for Dual Inventive. Employees can track the status of rented items and manage the stock.',
                appendDescription: "",
                imgHREF: "/projecten/inventory-management-system-dual-inventive/img/logo.webp",
                imgALT: "Inventory management system Dual Inventive",
                techniques: [
                    "C#",
                    "MySQL database",
                    "JWT",
                    "Software development in a team",
                ]
            },
            {
                name: "S4Engine",
                subtitle: "Hobby project",
                slug: "s4engine",
                href: "#s4engine",
                description: 'A game engine written in C++ with support for OpenGL, Scripts, materials, shaders, textures, meshes and more asset types. The engine runs on a self-written Entity Component System.',
                appendDescription: "",
                imgHREF: "/projecten/s4engine/img/logo.webp",
                imgALT: "S4Engine",
                techniques: [
                    "C++",
                    "OpenGL",
                    "Libraries als ImGui, tinyobjloader en GLFW"
                ]
            },
            {
                name: "Soft Cards",
                subtitle: "ICT study",
                slug: "soft-cards",
                href: "#project-soft-cards",
                description: 'Soft Cards is a visual programming language consisting of physical cards. These cards can be scanned using an augmented reality app on a smartphone or tablet. The script then controls a robot.',
                appendDescription: "",
                imgHREF: "/projecten/soft-cards/img/logo.webp",
                imgALT: "Soft Cards",
                techniques: [
                    "Unity Game Engine",
                    "Augmented Reality",
                    "Software development in a team",
                ]
            }
        ]
    }

    let pageName = LanguageChecker.isEnglish() ? "Projects" : "Projecten";
    return (
        <div id="section-projects" className="Project bg-primary d-flex h-auto">
            <div className="container-fluid d-flex w-100 flex-column">
                <div className="pt-5 px-0 px-lg-5">
                    <div className="fade-in fade-in-from-top w-100 position-relative" data-fade-in-start="0.9">
                        <h2 className="position-relative text-center font-weight-bold text-uppercase text-light text-break">{pageName}</h2>
                    </div>
                    <hr className="position-relative w-10 mx-auto mb-4 min-width-200px bg-light" />
                    <div className="w-100 d-flex flex-row flex-wrap">
                        <ProjectItems data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
