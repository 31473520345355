import React, { useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import HeaderText from '../components/header/HeaderText';
import Script from "../script";
import LanguageChecker from "../LanguageChecker";
import GalerijItem from '../components/sections/galerij/GalerijItem';

const GalerijImages = function(props) {
    let images = props.data;
    let imageComponents = [];
    let idx = 0;
    for(let image of images){
        let slug = image.slug;
        
        let imageAlt = image.title;
        let projectUrl = image.url;
        for(let url of image.images){
            let imageUrl = "/projecten/" + url;

            imageComponents.push(
                <GalerijItem key={idx} galerijItemInfo={{slug: slug, imageAlt: imageAlt, imageUrl: imageUrl, projectUrl: projectUrl}} />
            );
            idx++;
        }
    }
    return imageComponents;
}

const Galerij = function() {
    let data = [
        {
            title: "Klantenportaal - DataByte",
            title_en: "Customer portal - DataByte",
            slug: "customer-portal-databyte",
            url: "/projecten/#customer-portal-databyte",
            images: [
                "klantenportaal-databyte/img/logo.png"
            ]
        },
        {
            title: "Websitebeheer &amp; SEO - Autobedrijf Schoone",
            title_en: "Website configuration &amp; SEO - Autobedrijf Schoone",
            slug: "website-en-seo-autobedrijf-schoone",
            url: "/projecten/#autobedrijf-schoone-website-beheer-and-seo",
            images: [
                "website-en-seo-autobedrijf-schoone/img/logo.png"
            ]
        },
        {
            title: "Ansible Setup - Hobbyproject",
            title_en: "Ansible Setup - Hobby project",
            slug: "ansible-setup",
            url: "/projecten/#ansible-setup",
            images: [
                "ansible-setup/img/logo.png"
            ]
        },
        {
            title: "Store - Tax Model (Opleiding ICT)",
            title_en: "Store - Tax Model (ICT study)",
            slug: "store-tax-model",
            url: "/projecten/#store-tax-model",
            images: [
                "store-tax-model/img/logo.png",
                "store-tax-model/img/admin-overview.png",
                "store-tax-model/img/create-business-plan.png",
                "store-tax-model/img/create-service.png"
            ]
        },
        {
            title: "Beheersysteem voor inventaris - Dual Inventive (Opleiding ICT)",
            title_en: "Inventory management system - Dual Inventive (ICT study)",
            slug: "inventory-management-system-dual-inventive",
            url: "/projecten/#inventory-management-system-dual-inventive",
            images: [
                "inventory-management-system-dual-inventive/img/logo.webp",
                "inventory-management-system-dual-inventive/img/dualinventive_administratiesysteem.png"
            ]
        },
        {
            title: "S4Engine - Hobbyproject",
            title_en: "S4Engine - Hobby project",
            slug: "s4engine",
            url: "/projecten/#s4engine",
            images: [
                "s4engine/img/logo.webp"
            ]
        },
        {
            title: "Soft Cards - Opleiding ICT",
            title_en: "Soft Cards - ICT study",
            slug: "soft-cards",
            url: "/projecten/#soft-cards",
            images: [
                "soft-cards/img/logo.webp"
            ]
        }
    ];

    useEffect(() =>{
        Script();
    }, []);

    let activePage = LanguageChecker.isEnglish() ? "Gallery" : "Galerij";
    return (
        <div className="Galerij">
            <Navbar activePage={activePage}/>
            <HeaderText activePage={activePage}/>
            <section id="section-galerij" className="Galerij bg-primary d-flex h-auto">
                <div className="container-fluid d-flex w-100 my-5 flex-column">
                    <div className="pt-5 px-0 px-lg-5">
                        <div className="fade-in fade-in-from-top w-100 position-relative" data-fade-in-start="0.9">
                            <h2 className="position-relative text-center font-weight-bold text-uppercase text-light text-break">Galerij</h2>
                        </div>
                        <div className="fade-in fade-in-from-top w-100 position-relative mb-5" data-fade-in-start="0.9">
                            <hr className="position-relative w-10 mx-auto mb-3 min-width-200px bg-light" />
                        </div>
                        <div className="row mb-5 justify-content-center" id="galerij-images">
                            <GalerijImages data={data}/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Galerij;