import React, { useEffect } from 'react';
import PrivacyItem from "../components/sections/privacy-item/PrivacyItem";
import Navbar from '../components/navbar/Navbar';
import HeaderText from '../components/header/HeaderText';
import Script from "../script";
import LanguageChecker from "../LanguageChecker";

const PrivacyItems = function(props){
    let privacy = LanguageChecker.isEnglish() ? props.data.privacy_en : props.data.privacy;
    let privacyComponents = [];
    for(let i = 0; i < privacy.length; i++){
        privacyComponents.push(
            <PrivacyItem key={i} imgLeft={i % 2 === 0} privacyInfo={privacy[i]}/>
        );
    }
    return privacyComponents;
}

const Privacy = function() {
    let data = {
        privacy: [
            {
                name: "Privacy & dataverzameling van deze website",
                slug: "privacy-en-dataverzameling",
                description: 'Deze applicatie verzamelt geen persoonlijke gegevens van gebruikers. De site slaat ook geen cookies op.',
                classes: "text-center",
                imgHREF: "/img/vendor/freeimages/YellowIcon/cookie.webp",
                imgALT: "Cookie privacy en dataverzameling",
            },
        ],
        privacy_en:[
            {
                name: "Privacy & data collection by this website",
                slug: "privacy-and-data-collection",
                description: 'This application does not collect any personal data. The site also does not store any cookies.',
                classes: "text-center",
                imgHREF: "/img/vendor/freeimages/YellowIcon/cookie.webp",
                imgALT: "Cookie privacy and data collection",
            },
        ]
    }

    useEffect(() => {
        Script();
    }, []);

    let activePage = LanguageChecker.isEnglish() ? "Privacy" : "Privacy";
    let pageName = LanguageChecker.isEnglish() ? "Privacy" : "Privacy";
    return (
        <div className="Privacy">
            <Navbar activePage={activePage}/>
            <HeaderText activePage={activePage}/>

            <div id="section-privacy" className="bg-primary d-flex h-auto">
                <div className="container-fluid d-flex w-100 my-5 flex-column">
                    <div className="pt-5 px-0 px-lg-5">
                        <div className="fade-in fade-in-from-top w-100 position-relative" data-fade-in-start="0.9">
                            <h2 className="position-relative text-center font-weight-bold text-uppercase text-light text-break">{pageName}</h2>
                            <PrivacyItems data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
