function init() {
    let $ = window.$;
    $(function () {
        // $('.lazy').attr("lazy", "");
        let onScroll = function () {
            let windowScrollTop = $(window).scrollTop();
            $(".fade-in").each(function (index, element) {
                element = $(element);
                let top = element.offset().top - windowScrollTop;
                let start = element.attr("data-fade-in-start");
                top = top / $(window).height();
                if (top < start && !element.hasClass("faded-in")) {
                    element.addClass("faded-in");
                }
            });
        }

        $(window).scroll(onScroll);
        $(window).resize(function () {
            onScroll();
            for (let i = 1; i < 30; i++) {
                setTimeout(onScroll, i * 100);
            }
        });
        onScroll();
    });
}
export default init;
