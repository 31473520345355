import React, { useEffect } from 'react';
import ErvaringItem from '../ervaring-item/ErvaringItem';
import Script from "../../../script";
import LanguageChecker from '../../../LanguageChecker';

const ErvaringItems = function(props){
    let ervaring = LanguageChecker.isEnglish() ? props.data.experience_en : props.data.ervaring;
    let ervaringComponents = [];
    for(let i = 0; i < ervaring.length; i++){
        ervaringComponents.push(
            <ErvaringItem key={i} imgLeft={i % 2 === 0} ervaringInfo={ervaring[i]}/>
        );
    }
    return ervaringComponents;
}

const ErvaringSection = function() {
    let data = {
        ervaring: [
            {
                name: "Software­ontwikkelaar",
                subtitle: "Schooltoday",
                slug: "softwareontwikkelaar-bij-schooltoday",
                description: 'Bij Schooltoday ontwikkel en onderhoud ik diverse webapplicaties, waaronder MijnKleutergroep en apps voor intern gebruik. MijnKleutergroep is een leerlingvolgsysteem voor basisscholen (doelgroep: peuter- en kleuterleerkrachten). Ik vind het geweldig om mijn interesse in onderwijs te combineren met mijn vakgebied softwareontwikkeling.',
                appendDescription: "",
                imgHREF: "/vendor/softwareontwikkelaar-schooltoday/img/logo.svg",
                imgALT: "Schooltoday",
                techniques: [],
                fromTill: "2021 - heden"
            },
            {
                name: "HBO-ICT",
                subtitle: "Fontys University of Applied Sciences",
                slug: "hbo-ict-op-fontys-hogeschool",
                description: 'ICT is mijn passie sinds de middelbare school. Op Fontys heb ik softwareontwikkeling kunnen toepassen in groepsprojecten. Ook heb ik mij kunnen ontwikkelen in de vaardigheden presenteren, communicatie met stakeholders en werken in teamverband. In juli 2023 heb mijn studie afgerond.',
                appendDescription: "",
                imgHREF: "/vendor/hbo-opleiding-fontys-hogeschool-tilburg/img/logo-color.png",
                imgALT: "HBO-ICT op Fontys University of Applied Sciences",
                techniques: [],
                fromTill: "Augustus 2019 - juli 2023"
            },
            {
                name: "Stage software­ontwikkelaar",
                subtitle: "DataByte",
                slug: "stage-softwareontwikkelaar-bij-databyte",
                description: 'Tijdens mijn stage bij DataByte in Steenbergen heb ik een portaal ontwikkeld waar klanten wensen en bugs kunnen melden voor diensten die zij afnemen.',
                appendDescription: "",
                imgHREF: "/vendor/stage-databyte/databyte-logo-color.png",
                imgALT: "DataByte",
                techniques: [],
                fromTill: "Augustus 2021 - februari 2022"
            }
        ],
        experience_en:[
            {
                name: "Software developer",
                subtitle: "Schooltoday",
                slug: "software-developer-at-schooltoday",
                description: "At Schooltoday, I develop and maintain various web applications, including MijnKleutergroep, and apps for internal use. MijnKleutergroep is a student tracking system for primary schools (target audience: preschool and kindergarten teachers). I love combining my interest in education with software development.",
                appendDescription: "",
                imgHREF: "/vendor/softwareontwikkelaar-schooltoday/img/logo.svg",
                imgALT: "Schooltoday",
                techniques: [],
                fromTill: "2021 to date"
            },
            {
                name: "HBO-ICT",
                subtitle: "Fontys University of Applied Sciences",
                slug: "bachelor-level-hbo-niveau-it-study-fontys-hogeschool",
                description: 'ICT has been my passion since high school. At Fontys, I was able to apply software development in group projects. It also gave me the ability to develop skills like presenting, communicating with stakeholders and teamwork. In July 2023, I graduated at Fontys.',
                appendDescription: "",
                imgHREF: "/vendor/hbo-opleiding-fontys-hogeschool-tilburg/img/logo-color.png",
                imgALT: "HBO-ICT at Fontys University of Applied Sciences",
                techniques: [],
                fromTill: "August 2019 - July 2023"
            },
            {
                name: "Internship software developer",
                subtitle: "DataByte",
                slug: "internship-software-developer-at-databyte",
                description: 'During my internship at DataByte in Steenbergen, I developed an application for customers to request features and report bugs for software services they use.',
                appendDescription: "",
                imgHREF: "/vendor/stage-databyte/databyte-logo-color.png",
                imgALT: "DataByte",
                techniques: [],
                fromTill: "August 2021 - February 2022"
            }
        ]
    }

    useEffect(() => {
        Script();
    }, []);

    let pageName = LanguageChecker.isEnglish() ? "Experience" : "Ervaring";
    return (
        <div id="section-ervaring" className="bg-primary d-flex h-auto">
            <div className="container-fluid d-flex w-100 flex-column">
                <div className="pt-5 px-0 px-lg-5">
                    <div className="fade-in fade-in-from-top w-100 position-relative" data-fade-in-start="0.9">
                        <h2 className="position-relative text-center font-weight-bold text-uppercase text-light text-break">{pageName}</h2>
                    </div>
                    <hr className="position-relative w-10 mx-auto mb-4 min-width-200px bg-light" />
                    <div className="w-100 d-flex flex-row flex-wrap">
                        <ErvaringItems data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErvaringSection;
