import React, { useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import HeaderTextType from '../components/header/header-text-type';
import Script from "../script";

const NotFound = function() {
    useEffect(() => {
        Script();
        HeaderTextType(["Not Found...", "Redirecting you to the homepage..."]);
        setTimeout(() => {
            window.location = "/";
        }, 10000);
    }, []);

    let activePage = "NotFound";
    return (
        <div className="NotFound">
            <Navbar activePage={activePage}/>
            <header className="HeaderText bg-header-img w-100 vh-50 vh-xl-100 bg-header-img-1 bg-primary d-flex flex-column align-content-center">
                <div className="header-container h1 typewrite h-auto d-flex my-auto position-relative w-100" data-period="2000">
                    <p className="text-light header-text header-text-font-size h-auto"></p>
                </div>
                <div className="header-scroll-down h-auto align-self-bottom mb-0 justify-content-center flex-row">
                    <p className="text-light header-text d-none d-xl-flex header-scroll-down-font-size h-auto w-auto hide-on-scroll mx-auto justify-content-center align-content-center p-4"><i className="fas fa-arrow-down"></i> Scroll down <i className="fas fa-arrow-down"></i></p>
                </div>
            </header>
        </div>
    );
}

export default NotFound;
