function init() {
    let $ = window.$;
    $(function () {
        let isHoveringPageNav = false;
        $(window).scroll(function () {
            if (window.scrollY === 0) {
                if (!isHoveringPageNav) {
                    $("#page-nav").addClass("nav-bg-gradient");
                }
            } else {
                $("#page-nav").removeClass("nav-bg-gradient");
            }
        });
        if (window.scrollY === 0) {
            $("#page-nav").addClass("nav-bg-gradient");
        } else {
            $("#page-nav").removeClass("nav-bg-gradient");
        }

        $("#page-nav").hover(function () {
            isHoveringPageNav = true;
            if (window.scrollY === 0) {
                $("#page-nav").removeClass("nav-bg-gradient");
            }
        }, function () {
            isHoveringPageNav = false;
            if (window.scrollY === 0) {
                $("#page-nav").addClass("nav-bg-gradient");
            }
        });
    });
};

export default init;
