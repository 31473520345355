import React, { useEffect } from "react";
import LanguageChecker from "../../LanguageChecker";

import "./Modal.css";

const Modal = function(){
    useEffect(() => {
        let $ = window.$;
        window.doPushState = true;
        $(".modal").off("hide.bs.modal").on("hide.bs.modal", () => {
            if(window.doPushState){
                window.history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        });

        $(window).off("popstate.modal").on("popstate.modal", () => {
            if(window.location.hash === ""){
                window.doPushState = false;
                $(".modal").modal("hide");
                window.doPushState = true;
            }
        });
    }, []);

    return (
        <div>
            <div className="modal fade" id="gallery-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog rounded mx-auto" role="document" style={{maxWidth: "80vw"}}>
                    <div className="modal-content rounded">
                        <div className="modal-header d-flex flex-row justify-content-between p-3">
                            <h5 className="modal-title">Project</h5>
                            <button type="button" className="btn btn-secondary m-0" data-dismiss="modal" style={{fontSize: "2rem"}}>&times;</button>
                        </div>
                        <div className="modal-body position-relative py-2">
                            <div className="position-relative d-flex modal-body-image-container">
                                <div className="d-flex col m-0 position-absolute w-100 h-100 modal-body-image" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: "blur(3px) contrast(30%)"}}>
                                </div>
                                <div className="d-flex col m-0 position-absolute w-100 h-100 p-2" style={{zIndex: "1"}}>
                                    <div className="d-flex col m-0 p-0 modal-body-image" style={{backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pt-3 modal-body-description">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="projects-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog rounded mx-auto" role="document" style={{maxWidth: "80vw"}}>
                    <div className="modal-content rounded">
                        <div className="modal-header d-flex flex-row justify-content-between p-3">
                            <h5 className="modal-title">Project</h5>
                            <button type="button" className="btn btn-secondary m-0" data-dismiss="modal" style={{fontSize: "2rem"}}>&times;</button>
                        </div>
                        <div className="modal-body position-relative py-2">
                            <div className="position-relative d-flex modal-body-image-container">
                                <div className="d-flex col m-0 position-absolute w-100 h-100 modal-body-image" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: "blur(3px) contrast(30%)"}}>
                                </div>
                                <div className="d-flex col m-0 position-absolute w-100 h-100 p-2" style={{zIndex: "1"}}>
                                    <div className="d-flex col m-0 p-0 modal-body-image" style={{backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body modal-body-techniques pb-0">
                            {LanguageChecker.isEnglish() ? "Skills & Techniques:" : "Technieken en vaardigheden:"}
                            <ul className="mb-0" style={{paddingLeft: "2rem", listStyle: "disc"}}>

                            </ul>
                        </div>
                        <div className="modal-body pt-3 modal-body-description">

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="ervaring-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog rounded mx-auto" role="document" style={{maxWidth: "80vw"}}>
                    <div className="modal-content rounded">
                        <div className="modal-header d-flex flex-row justify-content-between p-3">
                            <h5 className="modal-title">Project</h5>
                            <button type="button" className="btn btn-secondary m-0" data-dismiss="modal" style={{fontSize: "2rem"}}>&times;</button>
                        </div>
                        <div className="modal-body position-relative py-0">
                            <div className="position-relative d-flex modal-body-image-container">
                                <div className="d-flex col m-0 position-absolute w-100 h-100 modal-body-image" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: "blur(3px) contrast(30%)"}}>
                                </div>
                                <div className="d-flex col m-0 position-absolute w-100 h-100 p-2" style={{zIndex: "1"}}>
                                    <div className="d-flex col m-0 p-0 modal-body-image" style={{backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body modal-body-techniques pb-0">
                            {LanguageChecker.isEnglish() ? "Skills & Techniques:" : "Technieken en vaardigheden:"}
                            <ul className="mb-0" style={{paddingLeft: "2rem", listStyle: "disc"}}>

                            </ul>
                        </div>
                        <div className="modal-body pt-3 modal-body-description">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;
