/*
Modified code from CodePen: https://codepen.io/hi-im-si/pen/DHoup
*/

function init(toType) {
    let $ = window.$;
    $(function () {
        $(window).scroll(function(){
            let element = $(".hide-on-scroll");
            if(window.scrollY <= window.innerHeight / 2){
                if(element.hasClass("opacity-0")){
                    element.removeClass("opacity-0");
                }
            }else{
                if(!element.hasClass("opacity-0")){
                    element.addClass("opacity-0");
                }
            }
        });
        let scrolling = false;
        $(".hide-on-scroll").click(function(){
            if(!scrolling){
                let scrollTime = 1000;
                let interval = 16;
                let scrollStart = window.scrollY;
                let stillToScroll = window.innerHeight - $(".navbar").outerHeight() - window.scrollY;
                let scrollEnd = scrollStart + stillToScroll;

                let calculateScroll = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);

                let i = 0;
                function doScroll(){
                    //let percentage = parseFloat(stillToScroll - scrollStart) / parseFloat(scrollEnd - scrollStart);
                    let percentage = parseFloat(interval * i) / parseFloat(scrollTime);
                    let scrollPixels = calculateScroll(percentage);
                    window.scroll(0, scrollStart + scrollPixels * (scrollEnd - scrollStart));
                    stillToScroll = scrollEnd - window.scrollY;
                    if(stillToScroll <= 0){
                        stillToScroll = 0;
                    }else{
                        i++;
                        setTimeout(doScroll, interval);
                    }
                    /*if(stillToScroll - scrollMax >= 0){
                        window.scroll(0, window.scrollY + scrollMax);
                        stillToScroll -= scrollMax;
                        setTimeout(doScroll, 33);
                    }else{
                        window.scroll(0, window.scrollY + stillToScroll);
                        stillToScroll = 0;
                    }*/
                    
                }
                doScroll();
            }
        });
        let TxtType = function (el, toRotate, period) {
            this.toRotate = toRotate.map(text => text.normalize());
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 2000;
            this.txt = '';
            this.tick();
            this.isDeleting = false;
            this.shouldStop = false;
        };

        TxtType.prototype.stop = function () {
            this.shouldStop = true;
        }

        TxtType.prototype.tick = function () {
            if(this.shouldStop){
                return;
            }
            let i = this.loopNum % this.toRotate.length;
            let fullTxt = Array.from(this.toRotate[i]);

            let ii = 0;
            if (this.isDeleting) {
                this.txt = fullTxt.slice(0, Array.from(this.txt).length - 1).join("");
                while(this.txt.lastIndexOf("&") > this.txt.lastIndexOf(";")){
                    this.txt = fullTxt.slice(0, Array.from(this.txt).length - 1).join("");
                    ii++;
                    if(ii >= 30){
                        break;
                    }
                }
                while(this.txt.endsWith("  ")){
                    this.txt = fullTxt.slice(0, Array.from(this.txt).length - 1).join("");
                    i++;
                }
            } else {
                this.txt = fullTxt.slice(0, Array.from(this.txt).length + 1).join("");
                while(this.txt.lastIndexOf("&") > this.txt.lastIndexOf(";")){
                    this.txt = fullTxt.slice(0, Array.from(this.txt).length + 1).join("");
                    ii++;
                    if(ii >= 30){
                        break;
                    }
                }
            }

            this.el.innerHTML = '<p class="text-light header-text header-text-font-size text-left">' + this.txt + '&nbsp;</p>';

            let that = this;
            let delta = 125 - Math.random() * 100;

            let deleteMultiplier = 2.0 / 1.5;

            if (this.isDeleting) {
                delta /= deleteMultiplier;
            }

            if (!this.isDeleting && this.txt === fullTxt.join("")) {
                delta = this.period;
                this.isDeleting = true;
                setTimeout(function () {
                    that.tick();
                }, delta);
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
                let bgHeaderImg = $(".bg-header-img");
                bgHeaderImg.addClass("blur");
                setTimeout(function () {
                    bgHeaderImg.removeClass("blur");
                }, 500);
                setTimeout(function(){
                    if (bgHeaderImg.hasClass("bg-header-img-1")) {
                        bgHeaderImg.addClass("bg-header-img-2");
                        bgHeaderImg.removeClass("bg-header-img-1");
                    } else if (bgHeaderImg.hasClass("bg-header-img-2")) {
                        bgHeaderImg.addClass("bg-header-img-3");
                        bgHeaderImg.removeClass("bg-header-img-2");
                    } else if (bgHeaderImg.hasClass("bg-header-img-3")) {
                        bgHeaderImg.addClass("bg-header-img-1");
                        bgHeaderImg.removeClass("bg-header-img-3");
                    }
                    setTimeout(function () {
                        that.tick();
                    }, delta);
                }, 250);
            }else{
                setTimeout(function () {
                    that.tick();
                }, delta);
            }
        };
        
        $(".typewrite").toArray().forEach((el) => {
            if(el.typer){
                el.typer.stop();
            }
            let period = el.getAttribute('data-keep-text-after-finish');
            if (toType) {
                el.typer = new TxtType(el, toType, period);
            }
        });
    });
};

export default init;
