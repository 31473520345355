import React from 'react';
import "./FooterItem.css";

const FooterItem = function(props) {
    return (
        <a className="FooterItem list-group-item list-group-item-action bg-primary border-bottom-0 d-flex col-4 flex-column justify-content-center rounded mb-3" href={props.linkTo} title={"Ga naar de pagina " + props.name}>
            <p className="text-light footer-item-text text-center">
                {props.name}
            </p>
            <i className={"fas fa-2x fa-md-4x text-center " + props.icon}></i>
        </a>
    );
}

export default FooterItem;
