import React, { useEffect } from 'react';
import NavbarItem from "./NavbarItem";
import "./Navbar.css";
import "./navbar-script";
import NavbarTransparencyGradient from "./navbar-transparency-gradient";
import LanguageChecker from "../../LanguageChecker";

const NavbarItems = function(props){
    let navItems = LanguageChecker.isEnglish() ? props.navItems_en : props.navItems;
    
    let items = [];
    let itemNames = Object.keys(navItems);
    for(let i = 0; i < itemNames.length; i++){
        items.push(
        <NavbarItem key={i} itemName={itemNames[i]} itemURL={navItems[itemNames[i]]} activePage={props.activePage}/>
        );
    }
    return items;
}

const Navbar = function(props) {
    let data = {
        navItems: {
            "Home": "/",
            "Galerij": "/galerij",
            "Social": {
                "GitLab": "https://gitlab.com/s4nderdevelopment",
                "GitHub": "https://github.com/S4nderDevelopment",
                "LinkedIn": "https://www.linkedin.com/in/sander-schoone-177965203/",
                "HackerOne": "https://hackerone.com/s4nderdevelopment?type=user",
            }
        },
        navItems_en: {
            "Home": "/home_en",
            "Gallery": "/gallery_en",
            "Social": {
                "GitLab": "https://gitlab.com/s4nderdevelopment",
                "GitHub": "https://github.com/S4nderDevelopment",
                "LinkedIn": "https://www.linkedin.com/in/sander-schoone-177965203/",
                "HackerOne": "https://hackerone.com/s4nderdevelopment?type=user",
            }
        }
    }

    useEffect(() => {
        NavbarTransparencyGradient();
    }, []);

    return (
        <nav id="page-nav" className="w-100 navbar navbar-expand-lg navbar-dark bg-dark-on-scroll left-0 right-0 top-0 h-auto z-index-2 position-fixed bg-dark trans-bg-250ms nav-bg-gradient">
            <a className="navbar-brand pl-4" href="/" title="Ga naar de homepagina">
                <h1 className="navbar-brand">Sander Schoone</h1>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarColor02">
                <ul className="navbar-nav float-right ml-lg-auto">
                    <NavbarItems activePage={props.activePage} navItems_en={data.navItems_en} navItems={data.navItems} />
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
